import React, { useState, useEffect } from "react";
import UserAccessLog from "../../components/user-access-log/user-access-log";
import { getUserAccessLogsFiltered } from "../../services/user-access-log-service";
import { getDataSources } from "../../services/data-source.service";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsers } from "../../services/user-service";

function UserAccessLogController() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [connections, setConnections] = useState([]);
  const [usersWithIds, setUsersWithIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({
    selectedConnectionId: "all",
    selectedUserId: "all",
    startDate: "",
    endDate: "",
    currentPage: 1,
    totalPages: 1,
  });

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    if (filters.startDate && filters.endDate) {
      fetchLogs();
    }
  }, [
    filters.selectedConnectionId,
    filters.selectedUserId,
    filters.currentPage,
  ]);

  const initializeData = async () => {
    try {
      const today = moment();
      const threeMonthsAgo = moment().subtract(3, "months");

      const formattedStartDate = threeMonthsAgo.format("YYYY-MM-DD");
      const formattedEndDate = today.format("YYYY-MM-DD");

      setFilters((prev) => ({
        ...prev,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }));

      await fetchConnections();
      await getListOfUser();
      await fetchLogs(formattedStartDate, formattedEndDate);
    } catch (error) {
      console.error("Error initializing data:", error);
    }
  };

  const fetchLogs = async (
    start = filters.startDate,
    end = filters.endDate
  ) => {
    try {
      if (!start || !end) return;

      const params = {
        startDate: start,
        endDate: end,
        connectionId:
          filters.selectedConnectionId !== "all"
            ? filters.selectedConnectionId
            : undefined,
        userId:
          filters.selectedUserId !== "all" ? filters.selectedUserId : undefined,
        pageNumber: filters.currentPage,
        pageSize: 1000,
      };

      const response = await getUserAccessLogsFiltered(params);
      const fetchedData = response.data?.result || [];
      const totalPages = response.data?.totalPages || 1;

      const rawColumns = Object.keys(fetchedData[0] || {}).filter(
        (key) => key !== "collectionId" && key !== "connectionId"
      );

      const formattedColumns = rawColumns.map((column) => ({
        columnName: column,
        displayName: updateDisplayName(column),
      }));

      const updatedRows = fetchedData.map(
        ({ collectionId, connectionId, dataSize, ...rest }) => ({
          ...rest,
          dataSize: formatDataSize(dataSize),
        })
      );

      setRows(updatedRows);
      setColumns(formattedColumns);
      setFilters((prev) => ({ ...prev, totalPages }));
      setIsLoading(false);

      if (fetchedData.length === 0 && filters.currentPage > 1) {
        setFilters((prev) => ({ ...prev, currentPage: 1 }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch logs. Please try again.");
      setIsLoading(false);
    }
  };

  const fetchConnections = async () => {
    try {
      const response = await getDataSources();
      if (response.data) {
        setConnections(response.data);
      }
    } catch (error) {
      console.error("Error fetching data sources:", error);
    }
  };

  const getListOfUser = async() =>{
    try {
      const response  = await getAllUsers();
      if(response?.hasError){
        toast.error(response?.errorMessage);
      }
      else{
        const users = response?.data?.map((tenant) => ({
          userName: tenant?.userName,
          userId: tenant?.userId,
        }));
        setUsersWithIds(users);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const formatDataSize = (size) => {
    if (size === null || size === undefined) {
      return "0 Bytes";
    }

    switch (true) {
      case size >= 1073741824:
        return `${(size / 1073741824).toFixed(2)} GB`;
      case size >= 1048576:
        return `${(size / 1048576).toFixed(2)} MB`;
      case size >= 1024:
        return `${(size / 1024).toFixed(2)} KB`;
      default:
        return `${size} Bytes`;
    }
  };

  const handleChange = (e, type) => {
    const value = e.target.value;
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleApplyFilters = async () => {
    setFilters((prev) => ({ ...prev, currentPage: 1 }));
    setIsLoading(true);

    await fetchLogs();

    setIsLoading(false);
  };

  const handlePageChange = (event, value) => {
    setFilters((prev) => ({ ...prev, currentPage: value }));
    setIsLoading(true);
  };

  const updateDisplayName = (displayName) => {
    const formatted = displayName
      ?.replace(/_/g, " ")
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/\s{2,}/g, " ")
      .trim();

    return formatted
      ? formatted.charAt(0).toUpperCase() + formatted.slice(1)
      : "";
  };

  return (
    <>
      <UserAccessLog
        columns={columns}
        rows={rows}
        usersWithIds={usersWithIds}
        connections={connections}
        selectedConnectionId={filters.selectedConnectionId}
        selectedUserId={filters.selectedUserId}
        onFilterChange={handleChange}
        startDate={filters.startDate}
        endDate={filters.endDate}
        onDateChange={handleChange}
        onApplyFilters={handleApplyFilters}
        currentPage={filters.currentPage}
        totalPages={filters.totalPages}
        onPageChange={handlePageChange}
        isLoading={isLoading}
      />
      <ToastContainer />
    </>
  );
}

export default UserAccessLogController;
