import {
  Paper,
  Dialog,
  TableHead,
} from "@mui/material";
import styles from "../../../styles/_exports.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useRef } from "react";
import { ASButton } from "../../../shared/as-button/as-button";
import "./modified-data-popup.scss";

const ModifiedDataPopUP = (props) => {
  const {
    newlyAddedColums,
    oldDeletedColumns,
    dialogState,
    closeModifiedDataDialog,
  } = props;

  const tableContainerRef = useRef(null);

  return (
    <div ref={props.prevStepRef}>
      <Dialog
        open={dialogState}
        handleCloseDialog={closeModifiedDataDialog}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            width: "520px",
            overflow: "unset",
          },
        }}
        sx={{ display: "flex", alignItems: "start", justifyContent: "center" }}
      >
        <Paper>
          <TableContainer ref={tableContainerRef}>
            <div className="group-table">
              <div className="table-style">
                <Table aria-labelledby="tableTitle" size="small">
                  <TableHead>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                      }}
                    >
                      <TableCell
                        align={"center"}
                        sx={{
                          color: styles.secondaryColor,
                          fontWeight: styles.semiBold,
                        }}
                        scope="row"
                        colSpan={1}
                      >
                        New Columns Added
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {newlyAddedColums?.map((column, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{column}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>

              <div className="table-style">
                <Table aria-labelledby="tableTitle" size="small">
                  <TableHead>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                      }}
                    >
                      <TableCell
                        align={"center"}
                        sx={{
                          color: styles.secondaryColor,
                          fontWeight: styles.semiBold,
                        }}
                        scope="row"
                        colSpan={2}
                      >
                        Deleted Columns
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                      }}
                    >
                      <TableCell
                        align={"left"}
                        sx={{
                          color: styles.secondaryColor,
                          fontWeight: styles.semiBold,
                        }}
                        scope="row"
                        colSpan={1} // Merge cells for "Deleted Column"
                      >
                        Display Name
                      </TableCell>

                      <TableCell
                        align={"left"}
                        sx={{
                          color: styles.secondaryColor,
                          fontWeight: styles.semiBold,
                        }}
                        scope="row"
                        colSpan={1} // Merge cells for "Deleted Column"
                      >
                        Column Name
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {oldDeletedColumns?.map((column) => {
                      return (
                        <TableRow>
                          <TableCell>{column?.displayName}</TableCell>

                          <TableCell>{column?.columnName}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </TableContainer>

          <div className="dialog-button">
            <ASButton
              onClick={closeModifiedDataDialog}
              primary={true}
              variant="contained"
            >
              Ok
            </ASButton>
          </div>
        </Paper>
      </Dialog>
    </div>
  );
};

export default ModifiedDataPopUP;
