import React, { useMemo } from "react";
import "./CheckoutPaymentArea.scss"
import { ASButton } from "../../../shared/as-button/as-button";
import CustomDropDown from "../../common/customDropDown/CustomDropDown";
import moment from "moment";
import { AppEnum } from "../../../constants/app-enum";
import { isNullOrEmpty } from "../../../services/data-source-validation";

export default function CheckoutPaymentArea(props) {

  const { featureData, userList, user, setUser, hoursList, hour, setHour, isDeveloperSetUp,
    developerPrice, totalPrice, onBuyPlan, pFlag, isUpdatePayment, updateSubscription, unusedAmount,
    boughtPlanDetails, subscriptionUId, buttonDisabled } = props;

    console.log(boughtPlanDetails);
  const formattedEndDate = useMemo(() => {
    return (subscriptionUId && boughtPlanDetails?.planName != AppEnum.SubscriptionType.Free)
      ? moment(boughtPlanDetails?.endDate).format("DD-MMM-YYYY")
      : moment().add(30, "days").format("DD-MMM-YYYY");
  }, [boughtPlanDetails?.endDate, subscriptionUId]);

  const buttonLabel = useMemo(() => {
    return pFlag ? "change subscription" :
      (featureData?.name == AppEnum.SubscriptionType.Free && !isDeveloperSetUp) ? "start free Subscription"
        : "start Subscription";
  }, [featureData])

  return (
    <div className="description-box">
      <div className="plan-heading">
        <div className="plan-type">Plan Type</div>
        <div className="units">Units</div>
        <div className="amount">Amount </div>
      </div>

      {/* it id for plan description */}
      <div className="plan-discription-text">
        <div>
          <div className="plan-type_1">
            <div className="small-business">{featureData?.name}</div>
            {featureData?.features?.map((item, index) => (
              <>
                <div className="normal-text" key={index}>
                  {item.featureDescription}
                </div>
              </>
            ))}
          </div>
          {!pFlag &&
            <div className="extra-space">
            </div>
          }
        </div>

        <div className={pFlag ? "units-value-update" : "units-value"}>
          <div>
            {
              featureData?.name == AppEnum.SubscriptionType.Free ?
                <div className="free">
                  <div className="free-value">{"1"}</div>
                  <div>user</div>
                </div> :
                <CustomDropDown
                  dropDownList={userList}
                  selectedValue={user}
                  setValue={setUser}
                />
            }
          </div>
          <div className="small-value">
            {isDeveloperSetUp && (
              <CustomDropDown
                dropDownList={hoursList}
                selectedValue={hour}
                setValue={setHour}
              />
            )}
          </div>
        </div>
        <div className="amount-value">
          USD{" "}
          <span>
            {featureData?.pricePeryear > 0
              ? featureData?.pricePeryear * user
              : 0}
          </span>{" "}
          <div className="amount-value-per-year">
            (per year)
            {isDeveloperSetUp && (
              <div className="small-value-usd">
                USD{" "}
                <span>
                  {developerPrice > 0 ? developerPrice * hour : 0}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={pFlag ? "totalSummary-view-update" : "totalSummary-view"}>
        <div className="payment-amount-area">
          {unusedAmount > 0 &&
            <div className="sub-amount-value">
              <div>Proration Adjustment</div>
              <div>-{" "}USD{" "}
                <span>
                  {unusedAmount}
                </span>{" "}
              </div>
            </div>}
          <div className="total-view">
            {" "}
            <div className="total-text">TOTAL</div>{" "}
            <div className="font-value">
              USD{" "}
              <span className="total-text-value">
                {totalPrice > 0 ? totalPrice : 0}
              </span>
            </div>{" "}
          </div>
          {
            ((featureData?.name != AppEnum.SubscriptionType.Free && !isDeveloperSetUp) && (totalPrice > 0 && isNullOrEmpty(boughtPlanDetails?.licenseTypeUId))) &&
            <div className="credit-card-info" >
              <label>No Credit-Card Required</label>
            </div>
          }
          {buttonDisabled ?
            <ASButton variant="contained" primary={false} sx={{ minWidth: "230px" }}>
              {buttonLabel}
            </ASButton> :
            <ASButton variant="contained" primary={true} sx={{ minWidth: "230px" }} disabled={(!isUpdatePayment && pFlag)} onClick={() => pFlag ? updateSubscription() : onBuyPlan()}>
              {buttonLabel}
            </ASButton>
          }
        </div>
      </div>
      {((featureData?.name != AppEnum.SubscriptionType.Free && !isDeveloperSetUp) && (totalPrice > 0 && isNullOrEmpty(boughtPlanDetails?.licenseTypeUId))) &&
        <div className="extra-info">
          <div >
            <label> This is a 30 Days Trial for <span>{featureData?.name}</span> Subscription </label>
          </div>
          <div >
            <label> We will ask for Payment Method and Payment after <span>30 Days</span> on {formattedEndDate} </label>
          </div>
        </div>
      }

    </div>
  )
}