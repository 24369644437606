import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Checkbox, FormControlLabel, IconButton, MenuItem, styled, Typography } from "@mui/material";
import React from "react";
import themeColor from "../../../styles/_exports.module.scss";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Paper } from "@mui/material";
import { ASButton } from "../../../shared/as-button/as-button";
import { CollectionParametersForm } from "../../../controllers/data-collections/add-new-data-collection/collection-parameters/collection-parameters-controller";
import { ASTextField } from "../../../shared/as-text-fields";
import { InputConstants } from "../../../constants/input-constant";
import './collection-parameters.scss';
import Stack from '@mui/material/Stack';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { AppEnum } from "../../../constants/app-enum";
import { tableBorderRight } from "../../../controllers/data-collections/add-new-data-collection/add-new-data-collection";
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import ExpandableView from "../../common/expandableView/expandableView";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ParametersTableRow from "./parameters-table-row";

// it changes the background color of the selected menu item
export const selectedMenuItemBackgroundColor = {
  "&& .Mui-selected": {
    backgroundColor: `${themeColor.primaryColor} !important`,
    color: `white !important`
  },
}

// it changes the background color of the selected autocomplete popper
export const StyledAutocompletePopper = styled('div')(({ theme }) => ({

  [`& .${autocompleteClasses.listbox}`]: {
    fontSize: '13px !important',

    [`& .${autocompleteClasses.option}`]: {
      '&[aria-selected="true"]': {
        backgroundColor: `${themeColor.primaryColor} !important`,
        color: `white !important`
      },

      '&[data-focus="true"]': {
        backgroundColor: `${themeColor.primaryColor} !important`,
        color: `white !important`
      },

      '&:hover': {
        backgroundColor: `${themeColor.avatarBackground}`,
        color: `${themeColor.secondaryColor}`
      },
    },
  },

  [`&.${autocompleteClasses.popper}`]: {
    marginTop: '-1em',
  },
}));

export const sysDataTypeList = [{ dataTypeId: 1, dataTypeName: 'Number' },
{ dataTypeId: 2, dataTypeName: 'Currency' },
{ dataTypeId: 3, dataTypeName: 'Date' },
{ dataTypeId: 4, dataTypeName: 'DateTime' },
{ dataTypeId: 5, dataTypeName: 'List' },
{ dataTypeId: 6, dataTypeName: 'String' },
{ dataTypeId: 7, dataTypeName: 'Boolean' },
{ dataTypeId: 8, dataTypeName: 'DynamicList' }
]

// it displays the parameter table's header titles
export const parameterTableHead = (isReadOnly, isShowSendAs) => <TableHead sx={{ minWidth: 750, borderTop: themeColor.borderBottom }}>
  <TableRow>
    {!isReadOnly &&
      <TableCell align='left' scope="row" component={'th'} sx={tableBorderRight} >

      </TableCell>
    }

    <TableCell align='left' scope="row" component={'th'} sx={tableBorderRight} >
      Name
    </TableCell>

    <TableCell align='left' scope="row" component={'th'} sx={tableBorderRight} >
      Display Name
    </TableCell>

    <TableCell align='left' scope="row" component={'th'} sx={tableBorderRight} >
      Parameter Type
    </TableCell>

    {isShowSendAs &&
      <TableCell align='left' scope="row" component={'th'} sx={tableBorderRight} >
        Send As
      </TableCell>
    }

    <TableCell align='left' scope="row" component={'th'} sx={tableBorderRight}  >
      Data Type
    </TableCell>

    <TableCell align='left' scope="row" component={'th'} sx={{ borderRight: `${isReadOnly ? '' : themeColor.borderBottom}` }} >
      Variable / Value
    </TableCell>

    {!isReadOnly && <TableCell align='left' scope="row" component={'th'}  >
    </TableCell>}

  </TableRow>
</TableHead>

const CollectionParameters = (props) => {

  const { addNewParameter, deleteParameter, editParameter, isAddDisable, isAddNewParameter,
    state, isEditMode, setIsEditMode, prevStepRef, hyperText, onShowVariablePop, onCheckIsMultiselectParameter, closeDialog, isTemplateCompany, onDragEnd, onCheckEnableSavingParameterValue} = props;
  const { collectionParameters } = props.state;

  const showSendAs = props.state.sysCollectionTypeId == 7;
  const isExpandedView = useSelector((state) => state.asState.isExpandedView);

  return (
    <div className="w-1250">
      <Paper sx={{ minHeight: "100px", padding: "20px", paddingBottom: "0px" }} ref={prevStepRef}>
        <ExpandableView>
          <div className="add-collection-btn-div">
            <Typography
              sx={{ color: themeColor.text_2, }}
              variant="body1" display="block"
            >
              Parameters
            </Typography>

            <IconButton
              disabled={isAddDisable || isEditMode}
              disableRipple
              sx={{ color: themeColor.primaryButton, }}
              onClick={() => addNewParameter()}
            >
              <AddCircleIcon sx={{ fontSize: '2rem' }} />

              <Typography
                sx={{ marginLeft: "5px", color: themeColor.text_2, }}
                variant="body1" display="block"
              >
                Add
              </Typography>
            </IconButton>


          </div>

          <div className="w-100 parameters-container">
            {
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="parameters">
                  {(provided, snapshot) => (
                    <TableContainer sx={{ minHeight: !(isAddNewParameter || isEditMode) ? `${55 + (collectionParameters?.length * 45)}px` : "100%" }}>
                      <Table
                        sx={{ minWidth: 750 }} size='small'
                        className='coll-parameter-table parameter-td'
                      >
                        {parameterTableHead(false, showSendAs)}

                        <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                          {
                            collectionParameters?.map((ele, index) => {

                              return (

                                !ele.edit ?
                                  <Draggable key={index} draggableId={`param-${index}`} index={index}>
                                    {(provided, snapshot) => (
                                      <TableRow hover
                                        ref={provided?.innerRef}
                                        {...provided?.draggableProps}
                                      >
                                        <ParametersTableRow
                                          index={index}
                                          isAddNewParameter={isAddNewParameter}
                                          isEditMode={isEditMode}
                                          collectionParametersCount={collectionParameters?.length}
                                          ele={ele}
                                          showSendAs={showSendAs}
                                          editParameter={editParameter}
                                          deleteParameter={deleteParameter}
                                          provided={provided}
                                        />
                                      </TableRow>
                                    )}
                                  </Draggable>
                                  :
                                  <TableRow>
                                    <TableCell colSpan={6} sx={{ borderBottom: 'none' }} key={index}>
                                      <div className="row-wrapper body">
                                        <div className="form-wrapper">

                                          <CollectionParametersForm
                                            onChangeParameterNameHandler={props.onChangeParameterNameHandler}
                                            onChangeParameterDisplayNameHandler={props.onChangeParameterDisplayNameHandler}
                                            onSelectParameterTypeHandler={props.onSelectParameterTypeHandler}
                                            onChangeSendAsHandler={props.onChangeSendAsHandler}
                                            onChangeVariableHandler={props.onChangeVariableHandler}
                                            onChangeFieldTypeHandler={props.onChangeFieldTypeHandler}
                                            onChangeUserDefinedVariableHandler={props.onChangeUserDefinedVariableHandler}
                                            onChangeDynamicListHandler={props.onChangeDynamicListHandler}
                                            onChangeDisplayColumnHandler={props.onChangeDisplayColumnHandler}
                                            onChangeValueColumnHandler={props.onChangeValueColumnHandler}
                                            onSelectVariableHandler={props.onSelectVariableHandler}
                                            fieldTypeList={props.fieldTypeList}
                                            parametersState={props.parametersState}
                                            setParametersState={props.setParametersState}
                                            setIsAddNewParameter={props.setIsAddNewParameter}
                                            setCollectionParameter={props.setCollectionParameter}
                                            state={state}
                                            isTemplateCompany={isTemplateCompany}
                                            index={index}
                                            hyperText={hyperText}
                                            onShowVariablePop={onShowVariablePop}
                                            setIsEditMode={setIsEditMode}
                                            onCheckOptionalParameter={props.onCheckOptionalParameter}
                                            onCheckIsMultiselectParameter={onCheckIsMultiselectParameter}
                                            onCheckEnableSavingParameterValue={onCheckEnableSavingParameterValue}
                                          />
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                              );
                            })
                          }

                          {
                            isAddNewParameter &&
                            <TableRow>
                              <TableCell colSpan={6} sx={{ borderBottom: 'none' }}>
                                <div className="row-wrapper body">
                                  <div className="form-wrapper">

                                    <CollectionParametersForm
                                      onChangeParameterNameHandler={props.onChangeParameterNameHandler}
                                      onChangeParameterDisplayNameHandler={props.onChangeParameterDisplayNameHandler}
                                      onSelectParameterTypeHandler={props.onSelectParameterTypeHandler}
                                      onChangeSendAsHandler={props.onChangeSendAsHandler}
                                      onChangeVariableHandler={props.onChangeVariableHandler}
                                      onChangeFieldTypeHandler={props.onChangeFieldTypeHandler}
                                      onChangeUserDefinedVariableHandler={props.onChangeUserDefinedVariableHandler}
                                      onChangeDynamicListHandler={props.onChangeDynamicListHandler}
                                      onChangeDisplayColumnHandler={props.onChangeDisplayColumnHandler}
                                      onChangeValueColumnHandler={props.onChangeValueColumnHandler}
                                      onSelectVariableHandler={props.onSelectVariableHandler}
                                      fieldTypeList={props.fieldTypeList}
                                      parametersState={props.parametersState}
                                      setParametersState={props.setParametersState}
                                      setIsAddNewParameter={props.setIsAddNewParameter}
                                      setCollectionParameter={props.setCollectionParameter}
                                      state={state}
                                      isTemplateCompany={isTemplateCompany}
                                      index={null}
                                      hyperText={hyperText}
                                      onShowVariablePop={onShowVariablePop}
                                      setIsEditMode={setIsEditMode}
                                      onCheckOptionalParameter={props.onCheckOptionalParameter}
                                      onCheckIsMultiselectParameter={onCheckIsMultiselectParameter}
                                      onCheckEnableSavingParameterValue={onCheckEnableSavingParameterValue}
                                    />
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Droppable>
              </DragDropContext>
            }
            {isExpandedView && <MuiDialog
              isShowPopUp={state.isShowDeletePopUp}
              secondaryButtonAction={closeDialog}
              primaryButtonAction={deleteParameter}
              closeDialog={closeDialog}
              secondaryButtonTitle="No"
              primaryButtonTitle="Yes"
              titleDescription="Are you sure you want to delete the parameter?"
            />}
          </div>
        </ExpandableView>
      </Paper>
    </div>
  );
}

export default CollectionParameters;


export const CollectionParametersFormView = (props) => {

  const { isSaveEnable, parameterIndex, onCheckOptionalParameter, onCheckIsMultiselectParameter, isTemplateCompany, onCheckEnableSavingParameterValue } = props;
  const { variableList, fieldTypeList, connectionList } = props.state;
  const { parameterName, parameterTypeCD, inputFieldTypeCD, defaultValue, displayName, sendAs, isOptionalParameter, isMultiSelectList, enableSavingParameterValue } = props.parametersState;

  return (
    <>
      <div className="width-75">
        <ASTextField
          className="text-field"
          type={InputConstants.text}
          fullWidth
          label="Parameter Name"
          variant="outlined"
          value={parameterName}
          onChange={props.onChangeParameterNameHandler}
        ></ASTextField>

        <ASTextField
          className="text-field"
          type={InputConstants.text}
          fullWidth
          label="Display Name"
          variant="outlined"
          value={displayName}
          onChange={props.onChangeParameterDisplayNameHandler}
        ></ASTextField>

        {props.state.sysCollectionTypeId == 7 && (
          <ASTextField
            className="text-field"
            type={InputConstants.text}
            fullWidth
            label="Send As *"
            variant="outlined"
            value={sendAs}
            onChange={props.onChangeSendAsHandler}
            select={true}
            InputLabelProps={{
              shrink: Boolean(sendAs),
            }}
            SelectProps={{
              MenuProps: {
                className: "menu-role-div",
                sx: selectedMenuItemBackgroundColor,
              },
            }}
          >
            <MenuItem value="Header">Header</MenuItem>
            <MenuItem value="Query String">Query String</MenuItem>
            <MenuItem value="Body Parameter">Body Parameter</MenuItem>
          </ASTextField>
        )}

        <ASTextField
          className="text-field"
          type={InputConstants.text}
          fullWidth
          label="Parameter Type"
          variant="outlined"
          value={parameterTypeCD}
          onChange={(event) =>
            props.onSelectParameterTypeHandler(event.target.value)
          }
          select={true}
          SelectProps={{
            MenuProps: {
              className: "menu-role-div",
              sx: selectedMenuItemBackgroundColor,
            },
          }}
        >
          <MenuItem value={AppEnum.ParameterTypeValue.Fixed}>{AppEnum.ParameterType.Fixed}</MenuItem>
          <MenuItem value={AppEnum.ParameterTypeValue.FixedHidden}>{AppEnum.ParameterType.FixedHidden}</MenuItem>
          <MenuItem value={AppEnum.ParameterTypeValue.UserInput}>{AppEnum.ParameterType.UserInput}</MenuItem>
          <MenuItem value={AppEnum.ParameterTypeValue.UserDefinedVariable}>{AppEnum.ParameterType.UserDefinedVariable}</MenuItem>
          <MenuItem value={AppEnum.ParameterTypeValue.SystemDefinedVariable}>{AppEnum.ParameterType.SystemDefinedVariable}</MenuItem>
        </ASTextField>

        {parameterTypeCD === AppEnum.ParameterTypeValue.UserInput && (
          <ASTextField
            className="text-field"
            type={InputConstants.text}
            fullWidth
            label="Field Type"
            variant="outlined"
            value={inputFieldTypeCD}
            onChange={props.onChangeFieldTypeHandler}
            select={true}
            SelectProps={{
              MenuProps: {
                className: "menu-role-div",
                sx: selectedMenuItemBackgroundColor,
              },
            }}
          >
            {fieldTypeList?.map((fieldType, index) => {
              return (
                <MenuItem value={fieldType.id} key={`field-type ${index}`}>
                  {fieldType.displayLabel}
                </MenuItem>
              );
            })}
          </ASTextField>
        )}

        {parameterTypeCD === AppEnum.ParameterTypeValue.UserDefinedVariable && (
          <Stack spacing={2}>
            <Autocomplete
              id="parameter-variable"
              freeSolo
              className="text-field"
              options={variableList.map((option) => ({
                sysDataTypeId: option?.sysDataTypeId,
                label: option?.name,
                name: option?.name,
                uId: option?.uId,
                templateConnectionId: option?.templateConnectionId
              }))}
              renderOption={(props, option) => {
                return (
                  <MenuItem
                    {...props}
                    key={`option-${option.uId}`} // Changed to a hyphen for better readability
                    className={
                      defaultValue === option.uId
                        ? "selected-opt"
                        : "autocomplete-option"
                    }
                  >
                    {option.label}
                    <>
                      {isTemplateCompany && option?.templateConnectionId && (
                        <div className="connection-name">
                          {`- `}{connectionList?.find(list => list.id === option.templateConnectionId || list.templateConnectionId === option.templateConnectionId)?.name || ''}
                        </div>
                      )}
                    </>
                  </MenuItem>
                );
              }}
              onChange={(event, variable) => {
                props?.onChangeUserDefinedVariableHandler(variable);
              }}
              value={defaultValue}
              PopperComponent={StyledAutocompletePopper}
              renderInput={(params) => (
                <>
                  <ASTextField
                    {...params}
                    onChange={(event) => {
                      props?.onChangeVariableHandler(event.target.value);
                    }}
                    label="Select a User Defined Variable"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: defaultValue && params.InputProps.endAdornment,
                    }}
                  />
                  <Typography className="show-list"
                    sx={{ cursor: "pointer" }}
                    onClick={() => props?.onShowVariablePop(AppEnum.ParameterType.UserDefinedVariable)}>
                    {"Create New Variable"}
                  </Typography>
                </>
              )}
            />
          </Stack>
        )}

        {parameterTypeCD === AppEnum.ParameterTypeValue.SystemDefinedVariable && (
          <ASTextField
            className="text-field"
            type={InputConstants.text}
            fullWidth
            label="Select a System Defined Variable"
            variant="outlined"
            select={true}
            SelectProps={{
              MenuProps: {
                className: "menu-role-div",
                sx: selectedMenuItemBackgroundColor,
              },
            }}
            value={defaultValue}
          >
            {variableList?.map((variable, index) => {
              return (
                <MenuItem
                  value={variable.name}
                  onClick={() =>
                    props.onChangeUserDefinedVariableHandler(variable)
                  }
                  key={`variable ${index}`}
                >
                  {variable.name}
                </MenuItem>
              );
            })}
          </ASTextField>
        )}


        {(parameterTypeCD !== AppEnum.ParameterTypeValue.UserDefinedVariable && parameterTypeCD !== AppEnum.ParameterTypeValue.SystemDefinedVariable) && (
          <Stack spacing={2}>
            <Autocomplete
              id="parameter-variable"
              freeSolo
              className="text-field"
              options={variableList.map((option) => ({
                sysDataTypeId: option.sysDataTypeId,
                label: option.name,
                uId: option.uId,
                isSysVariable: option.isSysVariable
              }))}
              renderOption={(props, option) => {
                return (
                  <MenuItem
                    {...props}
                    key={`option ${option.uId}`}
                    className={
                      defaultValue === option.uId
                        ? "selected-opt"
                        : "autocomplete-option"
                    }
                  >
                    {option.label}
                  </MenuItem>
                );
              }}
              onChange={(event, variable) => {
                props.onSelectVariableHandler(variable);
              }}
              value={defaultValue}
              PopperComponent={StyledAutocompletePopper}
              renderInput={(params) => (
                <ASTextField
                  {...params}
                  onChange={(event) => {
                    props.onChangeVariableHandler(event.target.value);
                  }}
                  label={
                    parameterTypeCD === AppEnum.ParameterType.UserInput
                      ? "Type a Default Value or Select from a System Defined or User Defined Variable"
                      : "Type a Fixed Value or Select from a System Defined or User Defined Variable"
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: defaultValue && params.InputProps.endAdornment,
                  }}
                />
              )}
            />
          </Stack>
        )}

        {(parameterTypeCD == AppEnum.ParameterTypeValue.UserDefinedVariable) &&
          <FormControlLabel control={
            <Checkbox
              checked={isMultiSelectList}
              onChange={(event) => onCheckIsMultiselectParameter(event)}
              sx={muiCheckboxStyle}
            />
          }
            className='collection-as-connection'
            label="Is Multiselect"
          />}

        {
          <FormControlLabel control={
            <Checkbox
              checked={isOptionalParameter}
              onChange={(event) => onCheckOptionalParameter(event)}
              sx={muiCheckboxStyle}
            />
          }
            className='collection-as-connection'
            label="Optional Parameter"
          />
        }

        {isTemplateCompany &&
          <FormControlLabel control={
            <Checkbox
              checked={enableSavingParameterValue}
              onChange={(event) => onCheckEnableSavingParameterValue(event)}
              sx={muiCheckboxStyle}
            />
          }
            className='collection-as-connection'
            label="Enable Saving ParameterValue"
          />
        }

        {inputFieldTypeCD &&
          parameterTypeCD === AppEnum.ParameterType.UserInput && props?.hyperText &&
          <Typography className="show-list"
            sx={{ cursor: "pointer" }}
            onClick={() => props?.onShowVariablePop(props?.hyperText)}>
            {"Create New "}
            "{props?.hyperText}"
            {" Variable"}
          </Typography>
        }


        <div className="d-flex ok-btn-div">
          <div className="px-2 parameter-cancel-btn">
            <ASButton onClick={() => props.cancelAddingParameter()}>
              CANCEL
            </ASButton>
          </div>

          <div
            className={
              !isSaveEnable
                ? "parameter-ok-button ok-btn"
                : "ok-btn collection-ok-btn"
            }
          >
            <ASButton
              onClick={() => props.addParameter()}
              primary={true}
              disabled={!isSaveEnable}
            >
              {" "}
              {parameterIndex !== null ? "Update" : `OK`}{" "}
            </ASButton>
          </div>
        </div>
      </div>
    </>
  );
};
