export class CollectionStepLabelConstant {
    static Select_Connection = "Select Connection";
    static Basic_Information = "Basic Information";
    static Collection_Type_And_Object = "Collection Type and Object";
    static Send_Body_As = "Send Body As";
    static Parameters = "Parameters";
    static Test_Data = "Test Data";
    static Data_Transformation_Script = "Data Transformation Script";
    static Data_After_Transformation = "Data After Transformation";
    static Select_Columns_And_Formatting = "Select Columns and Formatting";
    static Select_An_Object = "Select an Object";
    static Data_View_Columns = "Data View / Columns";
    static Collection_Column_Filters = "Filter Columns";
    static Select_Objects = "Select collections";
    static Merge_Query = "Merge Query";
}

export class ConnectionStepLabelConstant {
    static Select_The_Type_Of_Connection = "Select the type of Connection";
    static Connection_Detail = "Connection Detail";
    static Save_Connection = "Save Connection";
    static Test_Connection = "Test Connection";
    static Save_Connection_Details = "Save Connection Details";
    static Parameter = "Parameter";
}