import React, { useEffect, useState } from 'react'
import { FormContext } from "../../../contexts/form-context";
import CloudParameter from '../../../components/connections/cloud-parameter/cloud-parameter';
import { RestServiceConstants } from '../../../constants/rest-service-constant';
import { AppEnum } from '../../../constants/app-enum';

export default function CloudParameterController(props) {


    const { cloudConnectionTypesParameter, formData, setCloudConnectionTypesParameter} = props;

    useEffect(() => {

    }, [])

    const handleInputChange = (parameter, value) => {
        if (parameter.sysDataType === "integer") {
            if (!/^\d*$/.test(value)) {
                return;
            }
        }

        if (parameter.sysDataType === AppEnum.OtherDataBaseType.Boolean ) {
            value = value.toString();
        }

        if (formData && formData[parameter?.parameterType]) {
            formData[parameter?.parameterType] = {
                isValid: true,
                datasourceParameterTypeId: parameter?.id,
                value: value,
            };
        }

        setCloudConnectionTypesParameter((prevParameters) =>
            prevParameters.map((param) => {
                if (param.id === parameter.id) {
                    return {
                        ...param,
                        defaultValue: value,
                    };
                }
                return param;
            })
        );
    }

    return (
        <FormContext.Provider>
            <CloudParameter
                cloudConnectionTypesParameter={cloudConnectionTypesParameter}
                handleInputChange={handleInputChange}
                formData={formData}
            />
        </FormContext.Provider>
    )
}
