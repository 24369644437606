import { Checkbox, FormControlLabel, Paper, Typography } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import "./cloud-parameter.scss";
import { ASTooltip } from "../../../shared/as-tooltip/as-tooltip";
import { AppEnum } from "../../../constants/app-enum";
import styles from "../../../styles/_exports.module.scss";

export const muiCheckboxStyle = {
    color: styles.secondaryColor,
    '&.Mui-checked': {
        color: styles.checkboxColor,
    },
    '&.Mui-disabled': {
        color: 'grey',
    }
}

const CloudParameter = (props) => {

    const { cloudConnectionTypesParameter, handleInputChange } = props;

    return (
        <Paper
            sx={{
                minHeight: "100px",
                width: "900px",
                padding: "20px",
            }}
        >
            {cloudConnectionTypesParameter?.map((parameter, index) => (
                <div key={index} className="param-div">
                    {parameter.sysDataType === AppEnum.OtherDataBaseType.Boolean ? (
                        <FormControlLabel control={
                            <Checkbox checked={parameter?.defaultValue == "true"}
                              onChange={(e) =>handleInputChange(parameter, e.target.checked)}
                              sx={muiCheckboxStyle}
                            />
                          }
                            label={parameter?.displayName}
                          />
                    ) : (
                        <ASTextField
                            className="param-text-field"
                            key={index}
                            variant="outlined"
                            label={parameter?.displayName}
                            type={parameter.sysDataType === "integer" ? "number" : "text"}
                            inputProps={{
                                maxLength: parameter.maxLength,
                            }}
                            required={parameter.isRequired}
                            onChange={(e) => handleInputChange(parameter, e.target.value)}
                            value={
                                parameter?.defaultValue || ""
                            }
                        />
                    )}

                    {(parameter?.description && parameter.sysDataType != AppEnum.OtherDataBaseType.Boolean) && (
                        <ASTooltip text={parameter.description} />
                    )}
                </div>
            ))}
        </Paper>
    )
}

export default CloudParameter;