import { toast } from "react-toastify";
import * as connectionService from '../services/data-source.service';
import { RestServiceConstants } from "../constants/rest-service-constant";
import { getTokenFromAuthCode } from "./authCallback-service";
import { generateRandomString } from "../shared/code-challanges/code-challanges";

export const authorizeAgain = (setState, selectedConnection, windowRef,
    setOpenConnectionPopup, setStateConnectionParameter) => {
    setState((prevState) => {
        return {
            ...prevState, isTestingConnection: true
        }
    });

    const stateConParam = generateRandomString(50);

    var data = {
        client_id: selectedConnection.parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.CLIENT_ID)[0].parameterValue,
        redirect_uri: selectedConnection.parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.CALLBACK_URL)[0].parameterValue,
        auth_url: selectedConnection.parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE_INPUT.AUTH_URL)[0].parameterValue,
        scope: selectedConnection.parameters.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.SCOPE)[0].parameterValue,
        state: stateConParam
    };
    const queryString = Object.keys(data)
        .map(
            (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    windowRef.current = window.open(
        `/begin-oauth?${queryString}`,
        "_blank",
        "width=600,height=400"
    );

    setOpenConnectionPopup(true);
    setStateConnectionParameter(stateConParam);

    const checkPopupClosed = setInterval(() => {
        if (windowRef.current.closed) {
            // The popup window is closed
            clearInterval(checkPopupClosed);
            windowRef.current = null;
            setState((prevState) => {
                return {
                    ...prevState, isTestingConnection: false,
                    testCollectionError: "This process has been stopped midway. Please try again."
                }
            });
        }
    }, 3000);
};

export const getToken = async (selectedConnection, stateConnectionParameter, windowRef, setState,
    openConnectionPopup, setOpenConnectionPopup, setIsAuthorizeAgain, callBackFunc) => {
    try {
        var connection = {
            "name": selectedConnection.connectionName,
            "uId": selectedConnection.uId,
            "sysConnectionTypeId": selectedConnection?.baseConnectionTypeId || selectedConnection?.connectionTypeId,
            "isSaasAuthSource": false,
            "description": selectedConnection.description,
            "parameters": selectedConnection.parameters,
            "reestablishConnection": true
        };

        var exisitingAuthCode = connection.parameters?.filter(i => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE);
        if (exisitingAuthCode.length == 0) {

            const authCodeParameter = {
                configConnectionParameterTypeId: 2016,
                parameterName: RestServiceConstants.OAUTH2_INPUTS.AUTH_CODE,
                parameterValue: "",
                isValid: true
            };
            connection.parameters?.push(authCodeParameter);
        }

        const response = await getTokenFromAuthCode(stateConnectionParameter, connection);
        if (response?.data?.hasError) {
            if (openConnectionPopup && windowRef.current === null) {
                setOpenConnectionPopup(false);
            }
        }
        else {
            if (response?.data?.data?.access_token) {
                connection.parameters.filter((i) => i.parameterName == RestServiceConstants.OAUTH2_INPUTS.ACCESS_TOKEN)[0].parameterValue = response?.data?.data?.access_token;
                setIsAuthorizeAgain(false);
                setOpenConnectionPopup(false);
                if (selectedConnection?.templateConnectionId) {
                    setState((prevState) => { return { ...prevState, cloudCollectionError: response } });
                }
                else {
                    connectionService.updateDataSourceType(connection).then((updateRes) => {
                        setState((prevState) => {
                            return {
                                ...prevState, nativeCollectionError: "",
                                testCollectionError: "The connection was established successfully. Now you can test your collection.",
                                testedDataList: [],
                                testedDataColumnKeys: [],
                                isTestingConnection: false
                            }
                        });
                        callBackFunc();
                    }).catch((error) => {

                    });
                }
            }
        }
    } catch (error) {
        toast.error(error);
        setOpenConnectionPopup(false);
    }
};