export const LoginApiKey = () => {

    return {
        ClientId: 'webportal',
        ExcelClientId: 'excel-client',
        GrantType: 'password',
    }
}

export const UserRoleIds = () => {

    return {
        UserRoleId: '3_ROLE_RA',
        SuperAdmin: '1_ROLE_SA',
        CompanyAdmin: '2_ROLE_CA',
        CollectionAdmin: '3_ROLE_RA',
        CollectionUser: '4_ROLE_RU',
    }
}

export const MenuOptionId = () => {

    return {
        ChangePassword: 1,
        Logout: 2,
    }
}

export const RandomPassword = () => {
    return {
        allowedChars: "abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890",
        passwordLength: 6
    }
}

export const CollectionFilterId = () => {
    return {
        groupName: "groupName",
        connection: "connection",
        // category: "category",
        collectionAsSource: 'collectionAsSource'
    }
}

export const CollectionFilterPropertyName = () => {
    return {
        groupName: "groupName",
        connection: "name"
        // category: "categoryName"
    }
}

export const ParameterType = () => {
    return {
        Fixed: "Fixed",
        FixedHidden: "Fixed Hidden",
        UserInput: "User Input",
        UserDefinedVariable: "User Defined Variable",
        SystemDefinedVariable: "System Defined Variable"
    }
}

export const ParameterTypeValue = () => {
    return {
        Fixed: "Fixed",
        FixedHidden: "Fixed Hidden",
        UserInput: "User Input",
        UserDefinedVariable: "UDV",
        SystemDefinedVariable: "SDV"
    }
}

export const DataTypeId = () => {
    return {
        Number: 1,
        Currency: 2,
        Date: 3,
        DateTime: 4,
        List: 5,
        String: 6,
        Boolean: 7,
        DynamicList: 8,
    }
}


export const DataTypeName = () => {
    return {
        UserDefinedList: "User Defined List",
        Boolean: "Boolean",
        String: "String",
        DynamicList: "Dynamic List",
        UserDefinedVariable: "User Defined Variable",
        List: "List",
        VariableBoolean: "True or False",
        Text: "Text",
        DateTime: "Date Time"
    }
}

export const FeatureType = () => {
    return {
        CONNECTION_LIMIT: "CONNECTION_LIMIT",
        COLLECTION_LIMIT: "COLLECTION_LIMIT",
        TRANSFER_LIMIT: "TRANSFER_LIMIT",
        COLLECTION_USERS_LIMIT: "COLLECTION_USERS_LIMIT"
    }
}

export const LicenseTypeUIds = () => {
    return {
        FREE: "AS.FREE.1",
        BEGINNER: "AS.BEGN.2",
        PROFESSIONAL: "AS.PROF.3",
        Mid_Size_Business : "AS.MIDB.4",
        Enterprise : "AS.ENTP.5",
        SUPPORT: "AS.SUPP.7"
    }
}

export const DataBaseDataType = () => {
    return {
        String: "String",
        Number: "Number",
        Int: "Int64",
        Long: "Decimal",
        DateTime: "DateTime",
        Date: "Date",
        Currency: "Currency",
        Byte: "Byte",
    }
}

export const OtherDataBaseType = () => {
    return {
        Guid: "guid",
        Double: "double",
        Single: "single",
        TimeSpan: "timespan",
        Percentage: "percentage",
        Decimal: "decimal",
        Boolean: "boolean",
        Time: "time",
        DateTimeOffset: "datetimeoffset",
        SmallInt: "int16",
        Number: "number",
    }
}

export const SysCollectionTypeId = () => {
    return {
        SQL_SERVER: 3,
        REST: 7,
    }
}

export const FilterConditions = () => {
    return {
        BETWEEN: "BETWEEN",
        HasProperty: "HAS_PROPERTY",
        NotHasProperty: "NOT_HAS_PROPERTY"
    }
}

export const DefaultDisplayFormat = () => {
    return {
        General: "General",
    }
}

export const SDVConstants = () => {
    return {
        Today: "@Today",
        Yesterday: "@Yesterday",
        Tomorrow: "@Tomorrow",
        StartDayOfThisWeek: "@StartDayOfThisWeek",
        EndDayOfTheWeek: "@EndDayOfTheWeek",
        PreviousBusinessDay: "@PreviousBusinessDay",
        EndOfPreviousMonth: "@EndOfPreviousMonth",
        EndOfPreviousQuarter: "@EndOfPreviousQuarter",
        EndOfLastYear: "@EndOfLastYear",
        StartOfThisMonth: "@StartOfThisMonth",
        StartOfThisQuarter: "@StartOfThisQuarter",
        StartOfThisYear: "@StartOfThisYear",
        EndOfThisYear: "@EndOfThisYear",
        EndOfThisMonth: "@EndOfThisMonth",
        EndOfThisQuarter: "@EndOfThisQuarter"
    }
}
export const DateFormats = () => {
    return {
        Y_M_D: "YYYY-MM-DD"
    }
}



export const Routes = () => {
    return [
        { path: '/variables', userRoleIds: ['2_ROLE_CA', '3_ROLE_RA'] },
        { path: '/connections/add-connection', userRoleIds: ['2_ROLE_CA'] },
        { path: '/connections/edit-connection', userRoleIds: ['2_ROLE_CA'] },
        { path: '/book-an-expert', userRoleIds: ['2_ROLE_CA'] },
        { path: '/update-checkout', userRoleIds: ['2_ROLE_CA'] },
        { path: '/users/user-permission', userRoleIds: ['2_ROLE_CA'] },
        { path: '/data-collections/add-new-data-collection', userRoleIds: ['2_ROLE_CA', '3_ROLE_RA'] },
        { path: '/data-collections/update-collection', userRoleIds: ['2_ROLE_CA', '3_ROLE_RA'] },
        { path: '/data-collections/user-permissions', userRoleIds: ['2_ROLE_CA'] },
    ];
}

export const WebPages = () => {
    return {
        Variables: 'variables',
        AddCollection: 'add-new-data-collection',
        UpdateCollection: 'update-collection',
        AddConnection: 'add-connection',
        EditConnection: 'edit-connection',
        BookExpert: 'book-an-expert',
        UpdateCheckout: 'update-checkout'
    }
}

export const DataTypeFormats = () => {
    return {
        Integer: "Integer",
        Decimal: "12.34",
        Date: "YYYY-MM-DD",
        Time: "HH:MM:SS",
        DateTime: "YYYY-MM-DD HH:MM:SS",
        DateTimeOffset: "YYYY-MM-DD HH:MM±HH:MM",
        Guid: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
        Double: "12345.6789",
        TimeSpan: "HH:MM:SS.ffffff"
    }
}

export const VariableDataTypes = () => {
    return {
        Dynamic_List: "Dynamic List",
        User_List: "User Defined List",
        User_Varible: "User Defined Variable",
        Number: "Number",
        Text: "Text",
        Boolean: "True or False",
        Date: "Date",
    }
}


export const SysConnnectionTypeId = () => {
    return {
        Microsoft_SQL_Server : 1,
        REST_Service : 2,
        My_Sql : 4,
        Tally_Connection : 16,
        Shopify : 21,

    }
}

export const SubscriptionType = ()=>{
    return{
        Free : "Free",
        Mid_Size_Business : "Mid-Size Business",
        Enterprise : "Enterprise",
        Beginner : "Beginner",
        Professional : "Professional",
    }
}

export const MergeCollection = () => {
    return {
        MergeCollectionUId: 'mergeCollection',
        MergeSysConnectionTypeId: 11
    }
}

export const RestConnnectionTypes = () => {
    return {
        No_Auth : "No Auth",
        API_Key : "API Key",
        Bearer_Token : "Bearer Token",
        OAuth_2 : "OAuth 2.0",
        Basic_Auth : "Basic Auth",
    }
}

export const ReservedKeywords = () => {
    return { 
        filter: "filter_asheet",
        sort: "sort_asheet",
        limit: "limit_asheet"
    }
}

export const SchemaSource = () => {
    return {
        hasDynamicColumn: "hasDynamicColumn"
    }
}


export const AppEnum = (function states() {

    return {
        LoginApiKey: LoginApiKey(),
        UserRoleIds: UserRoleIds(),
        MenuOptionId: MenuOptionId(),
        RandomPassword: RandomPassword(),
        CollectionFilterId: CollectionFilterId(),
        ParameterType: ParameterType(),
        ParameterTypeValue: ParameterTypeValue(),
        DataTypeId: DataTypeId(),
        CollectionFilterPropertyName: CollectionFilterPropertyName(),
        FeatureType: FeatureType(),
        LicenseTypeUIds: LicenseTypeUIds(),
        DataBaseDataType: DataBaseDataType(),
        SysCollectionTypeId: SysCollectionTypeId(),
        DefaultDisplayFormat: DefaultDisplayFormat(),
        FilterConditions: FilterConditions(),
        DateFormats: DateFormats(),
        SDVConstants: SDVConstants(),
        Routes: Routes(),
        SDVConstants: SDVConstants(),
        VariableDataTypes: VariableDataTypes(),
        DataTypeName: DataTypeName(),
        WebPages: WebPages(),
        OtherDataBaseType: OtherDataBaseType(),
        DataTypeFormats: DataTypeFormats(),
        SysConnnectionTypeId: SysConnnectionTypeId(),

        MergeCollection: MergeCollection(),
        SubscriptionType: SubscriptionType(),
        RestConnnectionTypes:RestConnnectionTypes(),
        ReservedKeywords: ReservedKeywords(),
        SchemaSource: SchemaSource()
    }
})()