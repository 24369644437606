import React, { Fragment, useEffect, useState } from "react";
import { LoginSignUp } from "../../components/login-signup/login-signup";
import { RegexEmail } from "../../constants/regular-expression";
import { forgetPassword, resetPassword } from "../../services/forget-password.service.js";
import { useLocation, useNavigate } from "react-router-dom";
import { ForgetPasswordModel } from "./forget-password-state.js";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { isNullOrEmpty } from "../../services/data-source-validation.js";

const ForgetPasswordController = (props) => {

    const [state, setState] = useState(new ForgetPasswordModel());
    const [isDisabled, setIsDisabled] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        const resetToken = queryParams.get('token');
        const username = queryParams.get('email');
        if (resetToken && username) {
            setState((prevState) => {
                return {
                    ...prevState,
                    resetToken: resetToken,
                    email: username
                }
            });
        }
    }, [location.search]);

    const setErrorMessage = (message) => {

        setState((prevState) => {
            return {
                ...prevState,
                errorMessage: message,
            }
        });
    }

    const clearErrorMessage = () => {
        setState((prevState) => { return { ...prevState, errorMessage: null, } });
    }

    //Logics for forget password
    const onChangeEmailHandler = (event) => {
        setState((prevState) => {
            return { ...prevState, email: event.target.value, }
        });

        if (!state.isEmailValid)
            onBlurEmailHandler(event)

        if (state.errorMessage)
            clearErrorMessage();
    }

    const onBlurEmailHandler = (event) => {
        if (event.target.value) {
            let isEmailValid = RegexEmail.test(event.target.value);

            if (isEmailValid)
                setState((prevState) => { return { ...prevState, isEmailValid: true, } });
            else
                setState((prevState) => { return { ...prevState, isEmailValid: false, } });
        }
    }

    const handleSubmitForgetPassword = (e) => {
        e.preventDefault();

        if (state.email && state.isEmailValid) {

            if (state.errorMessage)
                clearErrorMessage();

            setIsDisabled(true);

            let formData = new FormData();
            formData.append('username', state.email);

            forgetPassword(formData)
                .then((response) => {

                    if (response?.hasError) {
                        setErrorMessage(response?.errorMessage);
                        toast.error(response?.errorMessage);
                    }
                    else {
                        // setState(new ForgetPasswordModel());
                        setErrorMessage('A Reset password link has been sent to your email');
                        toast.success("A Reset password link has been sent to your email");
                    }
                })
                .finally(() => {
                    setIsDisabled(false);
                })
        }
        else {
            setErrorMessage('Please fill the valid email to proceed');
        }
    }

    //Logics for reset password --->>    
    const handleSubmitResetPassword = (e) => {
        e.preventDefault();

        if(isNullOrEmpty(state?.password) || isNullOrEmpty(state?.confirmPassword)){
            setErrorMessage("Please enter password and confirm password.");
            return;
        }
        else if(state?.password.length < 6 || state?.confirmPassword.length < 6){
            setErrorMessage("Password must be at least 6 characters long.");
            return;
        }

        if (state.password && state.confirmPassword && state.isPasswordValidated) {

            if (state.errorMessage)
                clearErrorMessage();

            let formData = new FormData();
            formData.append('userName', state.email);
            formData.append('newPassword', state.password);
            formData.append('resetPasswordToken', state.resetToken);
            setIsDisabled(true);
            resetPassword(formData)
                .then((response) => {

                    if (response?.hasError)
                        setErrorMessage(response?.errorMessage);
                    else {
                        setState(new ForgetPasswordModel());
                        toast.success("Your password has been updated successfully.");
                        setTimeout(() => {
                            navigate('/login');
                        }, 1500);                        
                    }
                })
                .finally(() => {
                    setIsDisabled(false);
                })
        }
    }

    const onChangePasswordHandler = (event) => {

        setState((prevState) => {
            return { ...prevState, password: event.target.value, }
        });

        if (state.confirmPassword && !state.isPasswordValidated &&
            event.target.value === state.confirmPassword) {
            setPasswordValidate();
        }

        if (state.errorMessage)
            clearErrorMessage();
    }

    const onChangeConfirmPassHandler = (event) => {

        setState((prevState) => {
            return { ...prevState, confirmPassword: event.target.value, }
        });

        if (!state.isPasswordValidated && event.target.value === state.password) {
            setPasswordValidate();
        }
    }

    const setPasswordValidate = () => {

        setState((prevState) => {
            return { ...prevState, isPasswordValidated: true, }
        });
    }

    const validatePassAndConfirmPass = () => {
        if (state.password && state.confirmPassword) {

            if (state.password === state.confirmPassword) {

                setState((prevState) => {
                    return { ...prevState, isPasswordValidated: true, }
                });
            }
            else {

                setState((prevState) => {
                    return { ...prevState, isPasswordValidated: false, }
                });
            }
        }

    }

    const showHidePassword = () => {

        setState((prevState) => {
            return {
                ...prevState,
                isPasswordVisible: !prevState.isPasswordVisible,
            }
        });
    }

    const showHideConfirmPassword = () => {

        setState((prevState) => {
            return {
                ...prevState,
                isConfirmPasswordVisible: !prevState.isConfirmPasswordVisible,
            }
        });
    }

    return (
        <Fragment>

            <LoginSignUp
                pFlag={props.pFlag}
                state={state}
                handleSubmitForgetPassword={handleSubmitForgetPassword}
                onChangeEmailHandler={onChangeEmailHandler}
                onBlurEmailHandler={onBlurEmailHandler}
                showHidePassword={showHidePassword}
                showHideConfirmPassword={showHideConfirmPassword}
                onChangePasswordHandler={onChangePasswordHandler}
                onChangeConfirmPassHandler={onChangeConfirmPassHandler}
                validatePassAndConfirmPass={validatePassAndConfirmPass}
                handleSubmitResetPassword={handleSubmitResetPassword}
                isDisabled={isDisabled}
            />

        </Fragment>
    )
}

export default ForgetPasswordController;