import React, { Fragment, useEffect, useState } from "react";
import { AppEnum } from "../../constants/app-enum";
import { LoginModel } from "./login-state";
import * as loginService from "../../services/login-signup.service";
import { useNavigate } from "react-router-dom";
import { RegexEmail } from "../../constants/regular-expression";
import { LoginSignUp } from "../../components/login-signup/login-signup";
import { setToken } from "../../shared/local-storage-handler/local-storage-handler";
import { useASContext } from "../context-api/as-context";
import { useLocation } from "react-router-dom";
import querystring from 'querystring';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jwt-decode'
import { setSessionStorageItem } from "../../shared/session-storage-handler/session-storage-handler";

const LoginController = (props) => {
  const location = useLocation();
  const state = location.state;
  
  const [loginModel, setLoginModel] = useState(new LoginModel());
  const navigate = useNavigate();

  const asContext = useASContext();
  const [clickable, setClickable] = useState(false);

  // it handles email state whenever email textbox is changed
  const onChangeEmailHandler = (event) => {
    setLoginModel((prevState) => {
      return { ...prevState, email: event.target.value };
    });

    if (!loginModel.isEmailValid) onBlurEmailHandler(event);

    if (loginModel.errorMessage) clearErrorMessage();
  };

  // it handles password state whenever email textbox is changed
  const onChangePasswordHandler = (event) => {
    setLoginModel((prevState) => {
      return { ...prevState, password: event.target.value };
    });

    if (loginModel.errorMessage) clearErrorMessage();
  };

  // when user looses focus it checks if email is valid or not
  const onBlurEmailHandler = (event) => {
    if (event.target.value) {
      let isEmailValid = RegexEmail.test(event.target.value?.trim());

      if (isEmailValid)
        setLoginModel((prevState) => {
          return { ...prevState, isEmailValid: true };
        });
      else
        setLoginModel((prevState) => {
          return { ...prevState, isEmailValid: false };
        });
    }
  };

  // login
  const login = (event) => {
    event.preventDefault();
    if (loginModel.email && loginModel.password) {
      if (loginModel.errorMessage) clearErrorMessage();

      var loginRequest = {
        "client_id": AppEnum.LoginApiKey.ClientId,
        "grant_type": AppEnum.LoginApiKey.GrantType,
        "username": loginModel.email,
        "password": loginModel.password
      }
      setClickable(true);

      loginService.login(querystring.stringify(loginRequest)).then((response) => {
        if (response?.hasError){
          setErrorMessage(response?.errorMessage);
          toast.error(response?.errorMessage);
        }
        else if (response?.data) {
          setToken("token", response?.data?.access_token);
          setToken("refresh_token", response?.data?.refresh_token);
          if (response?.data?.access_token) {
              let decodedToken = jwt(response?.data?.access_token);
              if (decodedToken?.ASTenantUId){
                setToken("ASTenantId", decodedToken?.ASTenantUId)
                setSessionStorageItem("ASTenantId", decodedToken?.ASTenantUId)
              }
          }
          asContext?.tenants?.setSelectedTenant(null);
          if (state) {
            navigate(state, { state: state });
          } else {
            navigate("/");
          }
        }
        setClickable(false);
      }).catch((error) => {
        setErrorMessage(error);
        setClickable(false);
      });
    } else setErrorMessage("Please fill all the required fields to login");
  };

  // it clears the error message
  const clearErrorMessage = () => {
    setLoginModel((prevState) => {
      return { ...prevState, errorMessage: null };
    });
  };

  // it sets the error message state
  const setErrorMessage = (message) => {
    setLoginModel((prevState) => {
      return {
        ...prevState,
        errorMessage: message,
      };
    });
  };

  // it resets the state to initial value if view is changed
  const resetFormState = () => {
    setLoginModel(new LoginModel());
  };

  // it displays the entered password
  const showHidePassword = () => {
    setLoginModel((prevState) => {
      return {
        ...prevState,
        isPasswordVisible: !prevState.isPasswordVisible,
      };
    });
  };

  // dumb component
  return (
    <Fragment>
      <LoginSignUp
        pFlag={props.pFlag}
        loginModel={loginModel}
        onChangeEmailHandler={onChangeEmailHandler}
        onChangePasswordHandler={onChangePasswordHandler}
        onBlurEmailHandler={onBlurEmailHandler}
        login={login}
        resetFormState={resetFormState}
        showHidePassword={showHidePassword}
        clickable={clickable}
      />
    </Fragment>
  );
};

export default LoginController;
