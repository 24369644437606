import { axios } from "../core/axios.interceptor";


// api for getting collection type list
export async function getSubscriptionPlan() {
    const response = await axios.get(`subscriptions/plans`);
    return response;
}



export async function addBuyPlan(data) {
    const response = await axios.post(`subscriptions`, data);
    return response;
}

export async function updateSubscriptionPlan(redirectUrl,planId,licenseUnit,oldSubscriptionUId,newAmount) {
    const response = await axios.put(`subscriptions?redirectUrl=${redirectUrl}&planId=${planId}&licenseUnit=${licenseUnit}&oldSubscriptionUId=${oldSubscriptionUId}&newAmount=${newAmount}`);
    return response;
}

export async function getSubscription() {
    const response = await axios.get(`subscriptions`);
    return response;
}
