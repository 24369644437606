import { axios } from "../core/axios.interceptor";

const baseURL = "user-access-log/by-filters";

export async function getUserAccessLogsFiltered({
  startDate,
  endDate,
  pageNumber = 1,
  pageSize = 1000,
  connectionId,
  userId,
}) {
  const params = new URLSearchParams();

  if (userId && userId !== "all") {
    params.append("userId", userId);
  }

  if (connectionId && connectionId !== "all") {
    params.append("connectionId", connectionId);
  }

  params.append("startDate", startDate);
  params.append("endDate", endDate);
  params.append("pageNumber", pageNumber);
  params.append("pageSize", pageSize);

  const response = await axios.get(`${baseURL}?${params.toString()}`);
  return response;
}
