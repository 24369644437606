export class RestServiceConstants {
    static API_KEY = "REST.APIKey";
    static BEARER = "REST.Bearer";
    static OAUTH2 = "REST.OAuth2";
    static BASIC_AUTH = "REST.BasicAuth";
    static AWS_SIGN = "REST.AWSSign";
    static REQUIRE_PROXY_CONNECTOR = "REST.RequireProxyConnector";
    static CLOUD = "CLOUD";

    static AUTH_TYPE = "REST.AuthType";
    static API_ENDPOINT = "REST.OAuth2.APIEndPoint";
    static LOGIN_URL = "REST.LoginUrl";
    static JWT_JSON_PATH = "REST.JWTJsonPath";
    static REST_REQUIRE_PROXYCONNECTOR = "REST.RequireProxyConnector";
    static REST_TALLY_CONNECTOR_URL = "REST.TallyConnectorUrl";

    static API_KEY_INPUTS = {
        KEY: "REST.APIKey.Key",
        VALUE: "REST.APIKey.Value",
        SEND_AS: "REST.APIKey.SendAs"
    }

    static BASIC_AUTH_INPUTS = {
        USERNAME: "REST.BasicAuth.UserName",
        PASSWORD: "REST.BasicAuth.Password",
    }

    static OAUTH2_INPUTS = {
        HEADER_PREFIX: "REST.OAuth2.HeaderPrefix",
        GRANT_TYPE: "REST.OAuth2.GrantType",
        ACCESS_TOKEN_URL: "REST.OAuth2.AccessTokenUrl",
        REFRESH_TOKEN_URL: "REST.OAuth2.RefreshTokenUrl",
        OFFLINE_ACCESS_SCOPE: "REST.OAuth2.OfflineAccess",
        CLIENT_ID: "REST.OAuth2.ClientId",
        CLIENT_SECRET: "REST.OAuth2.ClientSecret",
        SCOPE: "REST.OAuth2.Scope",
        STATE: "REST.OAuth2.State",
        AUTHORIZATION_HEADER_NAME: "REST.OAuth2.AuthorizationHeaderName",
        AUTH_CODE: "REST.OAuth2.AuthCode",
        CODE_CHALLENGE: "REST.OAuth2.CodeChallenge",
        CODE_CHALLENGE_METHOD: "REST.OAuth2.CodeChallengeMethod",
        CODE_VERIFIER: "REST.OAuth2.CodeVerifier",
        SEND_CLIENT_AUTH_AS: "REST.OAuth2.SendClientAuthAs",
        SUPPORT_REFRESH_TOKEN: "REST.OAuth2.SupportRefreshToken",
        SEND_AUTHORIZATION_HEADER_IN_REFRESHTOKENREQUEST: "REST.OAuth2.REST.OAuth2.DoNotSendDefaultAuthorizationHeaderInRefreshTokenRequest",
        SEND_SCOPE_IN_REFRESHTOKENREQUEST: "REST.OAuth2.REST.OAuth2.DoNotSendScopeInRefreshTokenRequest",
        AUTH_CODE_INPUT: {
            CALLBACK_URL: "REST.OAuth2.AuthCode.CallBackUrl",
            AUTH_USING_BROWSER: "REST.OAuth2.AuthCode.AuthUsingBrowser",
            AUTH_URL: "REST.OAuth2.AuthCode.AuthUrl",
        },
        PASS_CRED_INPUT: {
            USERNAME: "REST.OAuth2.PassCred.UserName",
            PASSWORD: "REST.OAuth2.PassCred.Password",
        },
        ACCESS_TOKEN : "REST.OAuth2.AccessToken",
        ACCESS_TOKEN_EXPIRESAT : "REST.OAuth2.AcessToken.ExpiresAt",
        ACCESS_TOKEN_REFRESHTOKEN : "REST.OAuth2.AccessToken.RefreshToken",
        PARAMETERS: {
            AUTH_REQUEST : "REST.OAuth2.Parameters.AuthRequest",
            TOKEN_REQUEST : "REST.OAuth2.Parameters.TokenRequest",
            REFRESH_REQUEST : "REST.OAuth2.Parameters.RefreshRequest"
        },
        CLOUD : {
            STORE_NAME : "CLOUD.StoreName" 
        }

    }

    static BEARER_TOKEN = {
        SEND_AS: "REST.Bearer.SendBodyAs",
        JSON_BODY: "REST.Bearer.Body",
        PARAMETERS: "REST.Bearer.Parameters",
        HEADER_PREFIX: "REST.Bearer.HeaderPrefix",
        Authorization_Header: "REST.Bearer.AuthorizationHeaderName",
        Form_Key_Values: "REST.Bearer.FormKeyValues",
        Body_Parameters: "REST.Bearer.BodyParameters",
        SEND_AS_OPTION: [
            { label: "json", value: "Json" },
            { label: "form data", value: "Form" },
            { label: "x-www-form-encoded", value: "FormEncoded" }
        ]
    }
    static REST_API_NAV_CONSTANTS = {
        NO_AUTH: ""
    }
    static HTTPMETHODS = {
        SEND_AS: "restRequestMethod",
        JSON_BODY: "RESTSendAsCD",
        SEND_AS_OPTION: [
            { label: "GET", value: "GET" },
            { label: "POST", value: "POST" },
            { label: "PUT", value: "PUT" },
            { label: "PATCH", value: "PATCH" }
        ],
        SEND_BODY_AS_OPTION: [
            { label: "json/xml", value: "Json" },
            { label: "form data", value: "Form" },
            { label: "x-www-form-encoded", value: "FormEncoded" }
        ]
    }
}