import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from "@mui/material";
import React, { useMemo } from "react";
import "./connection-type.scss";
import styles from "../../../styles/_exports.module.scss";
import { ASTextField } from "../../../shared/as-text-fields";
import { useSelector } from "react-redux";
import { AppEnum } from "../../../constants/app-enum";

export function ConnectionType({
    usedCloudApplicationsId,
    selectedStep,
    selectedType,
    sourceType = [],
    onFocusSearchBoxHandler,
    isShrunk,
    onChangeSearchConnectorHandler,
    searchText,
    onBlurSearchBoxHandler,
    connectedConnectionTypeIds,
}) {

    const remainingFeatureBalance = useSelector((state) => state.asState.remainingFeatureBalance);
    const isDisabled = useMemo(() => {
        return (cardId) => {
            if (AppEnum.SysConnnectionTypeId.Tally_Connection == cardId && connectedConnectionTypeIds?.has(cardId)){ //condition for tally connector
                return true;
            }
            return (remainingFeatureBalance == null || remainingFeatureBalance > 0) ? false : remainingFeatureBalance <= 0 && !connectedConnectionTypeIds?.has(cardId);
        };
    }, [remainingFeatureBalance, connectedConnectionTypeIds]);

    return ( 
        <div className="main-connection-type-div">
            <div className="search-connector-input">
                <ASTextField
                    className="connector-search-box"
                    fullWidth
                    label="Search"
                    key="Search"
                    variant="outlined"
                    onFocus={onFocusSearchBoxHandler}
                    onBlur={onBlurSearchBoxHandler}
                    InputLabelProps={{ shrink: isShrunk }}
                    value={searchText}
                    onChange={(e) => onChangeSearchConnectorHandler(e.target.value)}
                />
            </div>

            <div className="connetion-type-label"> Natural Data sources </div>
            <div className="card-container-type">
                {sourceType.filter(card => card.templateConnectionId === null && !card.isFutureConnection).map((card) => (
                    <Card
                        key={card.key}
                        sx={{
                            minWidth: "261px",
                            border: selectedStep && selectedStep.description === card.description
                                ? `2px solid ${styles.primaryColor}` : "",
                            boxShadow: selectedStep && selectedStep.description === card.description
                                ? "none !important" : "",
                            backgroundColor:(isDisabled(card?.id))
                                ? `${styles.disabledColor}` : "",
                        }}
                        className='connection-card'
                    >
                        <CardActionArea
                            onClick={() => selectedType(card)}
                            className='card-action-area'
                            disabled={isDisabled(card?.id) ? true : false}
                        >
                            {
                                card.iconImage &&
                                <CardMedia
                                    component="img"
                                    sx={{ width: "auto !important", height: '44px' }}
                                    // image={`/assets/${card.datasourceTypeIconImage}`}
                                    // alt={card.datasourceTypeIconImage}
                                    image={`/assets/${card.iconImage}`}
                                    alt={card.iconImage}
                                />
                            }

                            <CardContent className="connection-name">
                                <Typography gutterBottom variant="body2" component="div" sx={{ marginBottom: '0px' }}>
                                    {card.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </div>
            {(sourceType.some(card => card.templateConnectionId !== null || (card.templateConnectionId === null && card.isFutureConnection)) || searchText != "") && <div style={{ marginTop: "30px" }}>
                <div className="connetion-type-label"> Cloud Applications </div>
                <div className="card-container-type-cloud">
                    {sourceType.filter(card => card.templateConnectionId !== null || (card.templateConnectionId === null && card.isFutureConnection)).map((card) => (

                        <Card
                            key={card.key}
                            sx={{
                                minWidth: "261px",
                                border: selectedStep && selectedStep.description === card.description
                                    ? `2px solid ${styles.primaryColor}` : "",
                                boxShadow: selectedStep && selectedStep.description === card.description || isDisabled(card?.id)
                                    ? "none !important" : "",
                                backgroundColor:isDisabled(card?.id)  
                                    ? `${styles.disabledColor}` : "",
                            }}
                            className='connection-card'
                            raised={isDisabled(card?.id)}
                        >
                            <CardActionArea
                                onClick={() => selectedType(card)}
                                className='card-action-area'
                                disabled={isDisabled(card?.id) ? true : false}
                            >
                                {(card.templateConnectionId === null && card.isFutureConnection) && <div className="crown-div">{card?.isConnectorAlreadyRequested && <div>(Already Requested)</div>}<img src={`/assets/crown.svg`}/></div>}

                                {
                                    card.iconImage &&
                                    <CardMedia
                                        component="img"
                                        sx={{ width: "auto !important", height: '44px' }}
                                        image={`/assets/${card.iconImage}`}
                                        alt={card.iconImage}
                                    />
                                }

                                <CardContent className="connection-name">
                                    <Typography gutterBottom variant="body2" component="div" sx={{ marginBottom: '0px' }}>
                                        {card.description}
                                        {usedCloudApplicationsId.has(card.templateConnectionId)?(<div style={{fontStyle:"italic"}}>
                                            connected
                                        </div>):null}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </div>
                <div>
                <Card
                        sx={{
                            minWidth: "261px",
                            maxWidth: "550px",
                            background: "transparent",
                            border: "1px solid #0000000a",
                            marginTop: "10px"
                        }}
                        className='connection-card'
                    >
                        <CardActionArea
                            onClick={() => selectedType(null,true)}
                            className='card-action-area'
                        >
                            {
                                <CardMedia
                                    component="img"
                                    sx={{ width: "auto !important", height: '44px' }}
                                    image={`/assets/connector-not-found.svg`}
                                    alt={"connector-not-found.svg"}
                                />
                            }

                            <CardContent className="connection-name">
                                <Typography gutterBottom variant="body2" component="div" sx={{ marginBottom: '0px' }}>
                                Your Connector is not in the list ? We can create your connector for free. <strong>Click to request</strong> us for your Connector 
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </div>}

        </div>
    );
}
