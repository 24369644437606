import { Backdrop, Box, Button, Card, Checkbox, Dialog, Modal, Typography } from "@mui/material";
import { Fragment } from "react"
import { ASTextField } from "../../../shared/as-text-fields";
import "./AddUserDefinedListPopup.scss";
import { ASButton } from "../../../shared/as-button/as-button";
import { muiCheckboxStyle } from "../../user-permission/user-permission";

const AddNewUserDefinedListPopup = (props) => {
    const {open, handleDialogClose, userDefinedValues} = props;
    return (
        <Fragment>
            <div>
                <Modal
                    open={props?.isOpen && props?.selectedDataType === "User Defined List"}
                    onClose={props?.onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={props?.style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    { props?.labelDuplicate.length > 0 ?
                        <>{`Labels - are duplicate in row no. ${props?.labelDuplicate}`}</>
                    : "" }                   
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>                   
                    { props?.valueDuplicate.length > 0 ?
                        <>{`Values - are duplicate in row no. ${props?.valueDuplicate}`}</>
                    : "" }
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {props?.message}
                    </Typography>
                    <div className="confirm-btn">
                        <ASButton onClick={props?.onClose}>{props?.noButtonLabel}</ASButton>
                        <div className="confirm-btn-div"></div>
                        <ASButton
                        onClick={props?.onPopUpSave}
                        primary={true}
                        >
                        {props?.yesButtonLabel}
                        </ASButton>
                    </div>
                    </Box>
                </Modal>
            </div>

            <Dialog open={open} handleDialogClose={handleDialogClose} BackdropComponent={Backdrop}
                BackdropProps={{
                    style: { backdropFilter: "blur(3px)" },
                    onClick: handleDialogClose,
                }}
                PaperProps={{ sx: { borderRadius: "10px", minWidth: "770px" } }}
                sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                <Card className="user-defined-box">
                {
                    <>
                        <div className="user-defined-heading">
                            <div className="user-header">User Defined List</div>
                            <div className="add-list-btn" onClick={props.handleAddListInput}>+ Add Value</div>
                        </div>
                        <div className="column-name">
                            <span class="label-text">Label</span>
                            <span class="value-text">Value</span>
                            <span class="default-text">Default</span>
                        </div>               
                        <div className="scroll">
                                
                                { userDefinedValues?.map((item, index) => (
                                    <div className="scroll-content">
                                        <div className="index">
                                        {index + 1}{"."}
                                        </div>
                                    <div className="input-field-padding">
                                        <div className="input-width" style={{ width: "300px" }}>
                                            <ASTextField
                                                key={1}
                                                className="text-field user-define-input"
                                                fullWidth
                                                label={"Label"}
                                                variant="outlined"
                                                value={item?.Label}
                                                onChange={(event) =>
                                                    props.handleChange2(index, event.target.value,"label")
                                                }
                                            />
                                        </div>
                                        <div className="input-width" style={{ width: "300px" }}>
                                            <ASTextField
                                                key={2}
                                                className="text-field user-define-input"
                                                fullWidth
                                                label={"Value"}
                                                variant="outlined"
                                                value={item?.Value}
                                                onChange={(event) =>
                                                    props.handleChange2(index, event.target.value,"value")
                                                }
                                            />
                                        </div>
                                        <div className="checkbox-div" >
                                            <Checkbox
                                                disabled={!item.Value && !item.Label}
                                                checked={(!item.Value && !item.Label) ? false : item?.Default}
                                                onChange={(event) => props.onCheckBoxClick(event, index)}
                                                sx={muiCheckboxStyle}
                                            />
                                        </div>
                                        <div
                                            className="minus-border-icon"
                                            onClick={() => props.handleDeleteListInput(index)}
                                        >
                                            {" "}
                                            <i class="minus-icon">-</i>
                                        </div>
                                    </div>
                                    </div>
                                ))}
                        </div>
                        <div className="btn-div">
                            <Button
                            onClick={handleDialogClose}
                            className="cancel-button"
                            >
                            Cancel
                            </Button>
                            {
                                props.variableUId ? 
                                (<ASButton
                                primary={true}
                                    variant="contained"
                                    onClick={() => props.updateHandler()}
                                >Update</ASButton>
                                ) : (
                                <ASButton 
                                primary={true}
                                variant="contained"
                                onClick={() => props.saveHandler()}
                                >Save</ASButton>)
                            }                         
                        </div>
                    </>
                    }
                </Card>
            </Dialog>
        </Fragment>)
}
export default AddNewUserDefinedListPopup;