import { Box, Stepper, Step, StepContent, styled, StepLabel, StepConnector, stepConnectorClasses, } from "@mui/material";
import { ASButton } from "../as-button/as-button";
import themeColor from "./../../styles/_exports.module.scss";
import "./as-stepper.scss";
import { ASSwitch } from "../as-switch/as-switch";
import { Link } from "react-router-dom";
import React, { useEffect, useMemo, useRef } from "react";
import { useASContext } from "../../controllers/context-api/as-context";
import { CollectionStepHeaderView } from "../../components/data-collections/collection-steps-view/collection-steps-view";
import { getSelectedCollectionObjectsPreview, optionalStepCollectionFilterColumn, optionalStepColumnAndFormatting, optionalStepDataAfterTransformation, optionalStepDataTransformationScript, optionalStepLabel, optionalStepLabels, optionalStepParameter, optionalStepTestData } from "./stepper-preview-components";



const ASStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-label": {
    color: themeColor.mutedColor,
    marginTop: "2px",
    marginLeft: "3px",
    fontSize: `16px`,
  },
  "& .Mui-active,& .Mui-completed": {
    color: `${themeColor.secondaryColor} !important`,
    marginTop: "2px",
    marginLeft: "3px",
    fontWeight: `${themeColor.semiBold} !important`,
    fontSize: `16px`,
  },
});

const StepConnectorLine = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    marginLeft: "4px",
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, icon } = props;

  return (
    <div
      className={
        active || completed
          ? "activeOrCompleteState step-button"
          : "step-button"
      }
    >
      <label className={completed ? "completed-icon" : ""}>
        {completed ? <i>&#10003;</i> : icon}
      </label>
    </div>
  );
}


const getOptionalStepPreview = (step, index, activeStep, isCloudSteps, isShowHeader, showSendAsStep, isDataModified) => {
  if (index === activeStep) {
    return null;
  }

  const { isCollectionType, isBasicInfo, isParameterSubmitted, isColumnAndFormatSubmitted, isDataFormationStep,
    isTestedData, isDataAfterFormatingStep, isCollectionFilterColumnSubmitted, optionalComponent, isCloudConnectionTypeParameters } = step || {};


  if (optionalComponent?.isSelectCollectionObjectsPreview) {
    const selectedCollections = optionalComponent?.labels?.join(", ")
    return getSelectedCollectionObjectsPreview('Selected collections', selectedCollections)
  }


  if (optionalComponent?.isMergeQueryStep && optionalComponent?.labels) {
    return getSelectedCollectionObjectsPreview('Sql query', optionalComponent?.labels)
  }

  if (isCollectionType) {
    return optionalStepLabels(activeStep, optionalComponent, isCollectionType);
  }

  if (isBasicInfo) {
    return optionalStepLabels(activeStep, optionalComponent, isCollectionType, step?.isCollectionAsDataSource, isCloudSteps, step?.hasMergeCollections);
  }

  if (isParameterSubmitted) {
    return optionalStepParameter(optionalComponent, activeStep);
  }

  if (isColumnAndFormatSubmitted) {
    return optionalStepColumnAndFormatting(optionalComponent, activeStep);
  }

  if (isDataFormationStep && optionalComponent?.restDataTransformationScript) {
    return optionalStepDataTransformationScript(activeStep, optionalComponent);
  }

  if (isTestedData && optionalComponent?.testedDataList) {
    return optionalStepTestData(optionalComponent.testedDataList, optionalComponent.testedDataColumnKeys, optionalComponent.returnRawData, activeStep,isDataModified);
  }

  if (isDataAfterFormatingStep && optionalComponent?.testedDataTransformedList) {
    return optionalStepDataAfterTransformation(activeStep, optionalComponent.testedDataTransformedList, optionalComponent.testedDataTransformedColumneKeys,
      optionalComponent.columnSchemaList, isShowHeader, showSendAsStep, optionalComponent.collectionColumnSchema);
  }

  if (isCollectionFilterColumnSubmitted) {
    return optionalStepCollectionFilterColumn(optionalComponent, activeStep);
  }

  if (isCloudConnectionTypeParameters) {
    return optionalStepLabels(activeStep, optionalComponent, false, false, false, isCloudConnectionTypeParameters);
  }

  return optionalComponent ? optionalStepLabel(optionalComponent) : null;
};

export const ASStepperView = (props) => {

  const { steps, showSendAsStep, activeStep, handleBack, handleSave, handleNext, isTestingConnection, uId, deleteConnection, isConnection,
    updateActiveStatus, activeStatus, isTemplateCollection, isSavingCollection, stepperViewFromConnection, isShowHeader, handleStepClick, getButtonWidth,
    stepRefs, scrollToActiveStep, isStepVisible, isCloudSteps, isTestedData,isDataModified } = props;


  useEffect(() => {
    setTimeout(() => {
      scrollToActiveStep();
    },600)


  }, [activeStep]);

  const asContext = useASContext();

  const isBackDisabled = useMemo(() => {
    const stepAfterColumnSelectionStep = asContext.templateCompany.isTemplateCompany ? steps?.length - 2 : steps?.length - 1;
    return (stepAfterColumnSelectionStep == activeStep && !stepperViewFromConnection && !isTestedData && !isCloudSteps);
  }, [steps?.length, activeStep, stepperViewFromConnection, isTestedData, isCloudSteps]);

  return (
    <Box sx={{ maxWidth: "100%" }}>

       {(!stepperViewFromConnection && uId) && <CollectionStepHeaderView
            steps={steps}
            activeStep={activeStep}
            handleStepClick={handleStepClick}
        />}

      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<StepConnectorLine />}
      >
        {steps.map((step, index) => {
          const isShowStep = isStepVisible(index);
          return (
            isShowStep && <Step
              key={step.label}
              id={`muiStepper${index}`}
              ref={stepRefs.current[index]}
            >
              <ASStepLabel
                sx={{ alignItems: "inherit" }}
                StepIconComponent={ColorlibStepIcon}
                optional={
                  <>
                    {getOptionalStepPreview(step, index, activeStep, isCloudSteps, isShowHeader, showSendAsStep,isDataModified)}
                  </>
                }
              >
                <div className="label-button-div">
                  <Link
                    onClick={() => handleStepClick(step.label)}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {step.label}
                  </Link>
                  <div style={{ display: "flex", columnGap: "15px" }}>
                    {(step.isShowDeleteButton ||
                      (uId && step.label === "Select Connection")) && (
                        <div>
                          <ASSwitch
                            checked={activeStatus}
                            onChange={(e) => updateActiveStatus(e)}
                          />
                          <span className="switch-btn-text">Active</span>
                        </div>
                      )}
                    {step.isShowDeleteButton && (
                      <ASButton
                        variant="contained"
                        sx={{ mr: 1 }}
                        primary={true}
                        onClick={() => deleteConnection()}
                      >
                        Delete
                      </ASButton>
                    )}
                  </div>
                </div>
              </ASStepLabel>

              <StepContent
                sx={{
                  maxWidth: getButtonWidth(step),
                  // '& .MuiCollapse-wrapperInner': {
                  //   width: '100%',
                  // },
                  // '& .MuiCollapse-wrapperInner .MuiPaper-root': {
                  //   width: 'auto',
                  // },
                  marginLeft: "16px",
                  paddingLeft: "26px",
                }}
              >
                {step.description}

                <Box sx={{ mb: 2 }}>
                  <div className="button-box">
                    <ASButton
                      variant="contained"
                      disabled={step.disabledBack || isTestingConnection || isBackDisabled}
                      onClick={() => {
                        handleBack();
                        // scrollToPreviousLabelRefStep()
                      }}
                      sx={{
                        visibility: index === 0 ? "hidden" : "visible",
                      }}
                    >
                      BACK
                    </ASButton>

                    {index === steps.length - 1 ? (
                      <ASButton
                        variant="contained"
                        onClick={handleSave}
                        disabled={
                          step.disabled ||
                          isTestingConnection ||
                          isTemplateCollection ||
                          isSavingCollection ||
                          (showSendAsStep && isShowHeader) ||
                          (stepperViewFromConnection &&
                            !(
                              step.description.props?.formData?.name?.value?.trim()
                                .length > 0
                            ))
                        }
                        sx={{ mr: 1 }}
                        primary={true}
                      >
                        {uId ? "Update" : "Save"}
                      </ASButton>
                    ) : (
                      <ASButton
                        variant="contained"
                        onClick={() => {
                          handleNext()
                        }}
                        disabled={step.disabled || isTestingConnection}
                        sx={{ mr: 1, marginRight: "0px" }}
                        primary={true}
                      >
                        NEXT
                      </ASButton>
                    )}
                  </div>
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
