import React from "react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "./user-access-log.scss";
import AgGridTable from "../common/ag-grid/ag-grid";
import { Paper, Select, MenuItem, FormControl, InputLabel, TextField, Typography, Pagination } from "@mui/material";
import { ASButton } from "../../shared/as-button/as-button";

const UserAccessLog = ({
  columns,
  rows,
  usersWithIds,
  connections,
  selectedConnectionId,
  selectedUserId,
  onFilterChange,
  startDate,
  endDate,
  onDateChange,
  onApplyFilters,
  currentPage,
  totalPages,
  onPageChange,
  isLoading,
}) => {
  const defaultColDef = {
    flex: 1,
    minWidth: 110,
    resizable: true,
    sortable: true,
    filter: true,
  };

  const maxPages = 15;
  const adjustedTotalPages = totalPages > maxPages ? maxPages : totalPages;

  return (
    <div>
      <h1 className="user-access-log-heading">User Access Log</h1>
      <div className="user-access-log-container">
        <Paper className="filter-paper">
          <div className="filter-container">
            <Typography className="filter-heading">By User</Typography>
            <FormControl
              fullWidth
              variant="outlined"
              className="styled-dropdown-container"
            >
              <InputLabel id="filter-by-user-label">Filter by User</InputLabel>
              <Select
                size="small"
                labelId="filter-by-user-label"
                value={selectedUserId}
                onChange={(e) => onFilterChange(e, "selectedUserId")}
                label="Filter by User"
                className="filter-options"
              >
                <MenuItem value="all">
                  <span>All</span>
                </MenuItem>
                {usersWithIds.map(({ userName, userId }) => (
                  <MenuItem key={userId} value={userId}>
                    <span className="dropdown-text">{userName}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body1" className="or-text">
              AND
            </Typography>

            <Typography className="filter-heading">By Connection</Typography>
            <FormControl
              fullWidth
              variant="outlined"
              className="styled-dropdown-container"
            >
              <InputLabel id="filter-by-connection-label">
                Filter by Connection
              </InputLabel>
              <Select
                size="small"
                labelId="filter-by-connection-label"
                value={selectedConnectionId}
                onChange={(e) => onFilterChange(e, "selectedConnectionId")}
                label="Filter by Connection"
                className="filter-options"
              >
                <MenuItem value="all">
                  <span>All</span>
                </MenuItem>
                {connections.map((source) => (
                  <MenuItem key={source.id} value={source.id}>
                    <div className="dropdown-item">
                      <img
                        src={`/assets/${source.iconImage}`}
                        alt={source.name}
                        className="dropdown-icon"
                      />
                      <span className="dropdown-text">{source.name}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body1" className="or-text">
              AND
            </Typography>

            <Typography className="filter-heading">By Date Range</Typography>
            <TextField
              label="Start Date"
              type="date"
              size="small"
              value={startDate}
              onChange={(e) => onDateChange(e, "startDate")}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                max: endDate,
              }}
              className="date-field"
            />
            <TextField
              label="End Date"
              type="date"
              size="small"
              value={endDate}
              onChange={(e) => onDateChange(e, "endDate")}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: startDate,
              }}
              className="date-field"
            />

            <ASButton
              primary={true}
              variant="contained"
              onClick={onApplyFilters}
              sx={{ width: "300px", fontSize: "14px" }}
            >
              Apply Filter
            </ASButton>
          </div>
        </Paper>

        {isLoading && (
          <div className="loading-message">Loading user access logs...</div>
        )}

        <Paper className="grid-paper">
          <div className="grid-container">
            <div className="grid-wrapper">
              <div className="ag-theme-alpine grid-content">
                {rows?.length > 0 && (
                  <AgGridTable
                    rows={rows}
                    columns={columns?.map((col) => ({
                      columnName: col?.columnName,
                      displayName: col?.displayName,
                    }))}
                    defaultColDef={defaultColDef}
                    isShowHeader={true}
                    showHeaderIcon={true}
                  />
                )}
              </div>
            </div>

            <div className="total-rows-container">
              <Typography className="total-rows-text">
                Total Rows: {rows?.length || 0}
              </Typography>
            </div>

            <div className="pagination-container">
              <Pagination
                count={adjustedTotalPages}
                page={currentPage}
                onChange={onPageChange}
                color="primary"
                size="small"
                siblingCount={1}
                boundaryCount={1}
                className="pagination"
              />
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default UserAccessLog;
