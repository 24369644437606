import { useEffect,useState } from "react";
import { RestApiView } from "../../../../components/connections/connection-details/rest-api/rest-api-view";
import { RestServiceConstants } from "../../../../constants/rest-service-constant";
import { formValidationCheck, handleChangeInputValidation } from "../../../../services/data-source-validation";
import { Paper, Typography } from "@mui/material";
import { ASButton } from "../../../../shared/as-button/as-button";
import { ConnectingAnimationElement } from "../../../../constants/common-svgs";
import MessageToaster from "../../../../shared/message-toaster/message-toaster";
import TallyConnectorController from "./tally-connector-controller";
import { AppEnum } from "../../../../constants/app-enum";

export const RestApi = (props) => {
    const { datasourceTypeParameters, saveFormData,
        formData,
        isFormInvalid, isTemplateConnection, isUpdatingTemplateConnection, templateData, testConnection, disconnectConnection,
        selectedStep, isTestingConnection, errorMessage, isCLoudConnectionDisconnected, setIsClientDataUpdated,existingClientData,isTally,uID } = props;

    const [requireProxyConnector,setRequireProxyConnector] = useState(false);

    let index = datasourceTypeParameters.findIndex((ele) => ele.parameterType === RestServiceConstants.LOGIN_URL);

    const loginUrlParameters = datasourceTypeParameters[index];

    index = datasourceTypeParameters.findIndex((ele) => ele.parameterType === RestServiceConstants.API_ENDPOINT);

    const apiEndPointParameters = datasourceTypeParameters[index];

    index = datasourceTypeParameters.findIndex((ele) => ele.parameterType === RestServiceConstants.AUTH_TYPE);

    const authTypeParameters = datasourceTypeParameters[index];

    index = datasourceTypeParameters.findIndex((ele) => ele.parameterType === RestServiceConstants.JWT_JSON_PATH);

    const jwtTypeParameters = datasourceTypeParameters[index];

    const restApiKeyParameters = datasourceTypeParameters.filter((_ele) =>
        _ele.parameterType.includes(RestServiceConstants.API_KEY)
    );
    const bearerParameters = datasourceTypeParameters.filter((_ele) =>
        _ele.parameterType.includes(RestServiceConstants.BEARER)
    );

    const basicAuthParameters = datasourceTypeParameters.filter((_ele) =>
        _ele.parameterType.includes(RestServiceConstants.BASIC_AUTH)
    );

    const oauth2Parameters = datasourceTypeParameters.filter((_ele) =>
        _ele.parameterType.includes(RestServiceConstants.OAUTH2)
    );

    index = datasourceTypeParameters.findIndex((ele) => ele.parameterType === RestServiceConstants.REST_REQUIRE_PROXYCONNECTOR);
    const proxyConnectorParameter = datasourceTypeParameters[index];

    index = datasourceTypeParameters.findIndex((ele) => ele.parameterType === RestServiceConstants.REST_TALLY_CONNECTOR_URL);
    const tallyConnectorUrl = datasourceTypeParameters[index];


   useEffect(() => {
        const formObjectC = {};
        formObjectC.name = {
            value: (formData && formData?.name?.value) || "",
            isValid: (formData && !!formData?.name?.value) || true,
        };

        formObjectC.templateConnectionId = {
            value: (formData && formData?.templateConnectionId?.value) || 0,
            isValid: (formData && !!formData?.templateConnectionId?.value) || true,
        };

        if (formData?.uID) {
            formObjectC.uID = { value: formData.uID.value || "", isValid: true };
        }

        const bearer = bearerParameters.find(ele =>
            ele.parameterType === RestServiceConstants.BEARER_TOKEN.PARAMETERS) || {};

        datasourceTypeParameters?.forEach((input) => {
            formObjectC[input.parameterType] = {
                datasourceParameterTypeId: input.id,
                value: (formData && formData[input.parameterType])?.value || (!!formData && input?.defaultValue) || (bearer.parameterType === input.parameterType ? JSON.stringify([]) : ""),
                isValid:
                    !!(formData && formData[input.parameterType])?.value || !!input?.defaultValue ||
                    !input.isRequired,
                errorMsg: (formData && formData[input.parameterType])?.errorMsg,
            };
        });

        //clearing client essential data for new template connection with cloud application
        if (isTemplateConnection) {
            // const authType = formObjectC[authTypeParameters.parameterType]?.value;
            // templateService(formObjectC, authType, templateData, apiEndPointParameters, oauth2Parameters, basicAuthParameters, bearerParameters, restApiKeyParameters);
        }

        setRequireProxyConnector(formObjectC[RestServiceConstants.REQUIRE_PROXY_CONNECTOR]?.value === "true");
        saveFormData(formObjectC);
        formValidationCheck(formObjectC, isFormInvalid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeInput = (dataSource, event) => handleChangeInputValidation(dataSource,
        event, formData, saveFormData, datasourceTypeParameters, isFormInvalid);

    const onCheckRequireProxyConnector = (event) => {
        setRequireProxyConnector(event.target.checked);
    
        formData[proxyConnectorParameter.parameterType] ={
                value: event.target.checked,
                datasourceParameterTypeId: formData[proxyConnectorParameter.parameterType].datasourceParameterTypeId,
                errorMsg: formData[proxyConnectorParameter.parameterType].errorMsg,
                isValid: formData[proxyConnectorParameter.parameterType].isValid
        };
    }

    return (
        <>
            {
                (isTemplateConnection || isUpdatingTemplateConnection) && (formData[RestServiceConstants.AUTH_TYPE]?.value === AppEnum.RestConnnectionTypes.OAuth_2 && (formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]?.value === "authorization_code" || formData[RestServiceConstants.OAUTH2_INPUTS.GRANT_TYPE]?.value === "authorization_code_with_pkce")) ?
                    (
                        <OAuthComponent
                            testConnection={testConnection}
                            selectedStep={selectedStep}
                            isTestingConnection={isTestingConnection}
                            errorMessage={errorMessage}
                            isUpdatingTemplateConnection={isUpdatingTemplateConnection}
                            formData={formData}
                            disconnectConnection={disconnectConnection}
                            isCLoudConnectionDisconnected={isCLoudConnectionDisconnected}
                        />
                    ) : 
                    (isTemplateConnection || isUpdatingTemplateConnection) &&  isTally ?
                    (
                        <TallyConnectorController formData={formData}
                                                  isFormInvalid={isFormInvalid}
                                                  basicAuthParameters={basicAuthParameters}
                                                  tallyConnectorUrl={tallyConnectorUrl}
                                                  proxyConnectorParameter={proxyConnectorParameter}
                                                  handleChangeInput={handleChangeInput}/>
                    ) :
                    (
                        formData && !(isTemplateConnection || isUpdatingTemplateConnection) && <RestApiView
                            formData={formData}
                            loginUrlParameters={loginUrlParameters}
                            restApiKeyParameters={restApiKeyParameters}
                            bearerParameters={bearerParameters}
                            jwtTypeParameters={jwtTypeParameters}
                            handleChangeInput={handleChangeInput}
                            authTypeParameters={authTypeParameters}
                            isFormInvalid={isFormInvalid}
                            basicAuthParameters={basicAuthParameters}
                            oauth2Parameters={oauth2Parameters}
                            apiEndPointParameters={apiEndPointParameters}
                            isTemplateConnection={isTemplateConnection}
                            isUpdatingTemplateConnection={isUpdatingTemplateConnection}
                            saveFormData={saveFormData}
                            setIsClientDataUpdated={setIsClientDataUpdated}
                            existingClientData={existingClientData}
                            requireProxyConnector={requireProxyConnector}
                            onCheckRequireProxyConnector={onCheckRequireProxyConnector}
                            uID={uID}
                        />
                    )
            }
        </>
    );
};

const OAuthComponent = (props) => {
    const { selectedStep, testConnection, isTestingConnection, errorMessage, isUpdatingTemplateConnection, disconnectConnection, isCLoudConnectionDisconnected } = props;

    return (
        <Paper
            sx={{
                minHeight: "100px",
                width: "900px",
                padding: "20px",
            }}
        >

            {
                isTestingConnection ? (
                    <div className="connecting-spinner-div">
                        {ConnectingAnimationElement}

                        <Typography className="connecting-dots">Connecting</Typography>
                    </div>
                ) : (

                    isUpdatingTemplateConnection && !isCLoudConnectionDisconnected ? (
                        <div>
                            <div style={{ display: "flex", gap: "125px", alignItems: "center" }}>
                                Already Connected to {selectedStep.description}
                                </div>
                                <div style={{display: "flex" ,justifyContent: "flex-end"}}>
                                            <ASButton
                                                sx={{ mr: 1 }}
                                                onClick={disconnectConnection}
                                            >
                                                DISCONNECT
                                            </ASButton>

                                            <ASButton
                                                variant="text"
                                                sx={{ mr: 1 }}
                                                primary={true}
                                                onClick={testConnection}
                                            >
                                                CHECK CONNECTION
                                            </ASButton>
                                </div>
                                {errorMessage != null && <MessageToaster errorMessage={errorMessage} />}
                        </div>) : (
                            <>
                                <div style={{ display: "flex", gap: "125px", alignItems: "center" }}>
                                    Connect to {selectedStep.description}
                                </div>
                                    <div style={{display: "flex" ,justifyContent: "flex-end"}}>
                                        <ASButton
                                            variant="text"
                                            sx={{ mr: 1 }}
                                            primary={true}
                                            onClick={testConnection}
                                        >
                                            CONNECT
                                        </ASButton>
                                    </div>
                                {errorMessage && <MessageToaster errorMessage={errorMessage} />}
                            </>

                    )

                )
            }



        </Paper>
    );
};