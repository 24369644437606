import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import styles from "../../styles/_exports.module.scss";
import "./settings.scss";
import { FormatListBulleted } from "@mui/icons-material";
import { useASContext } from "../../controllers/context-api/as-context";
import { AppEnum } from "../../constants/app-enum";

export const Settings = () => {
    const asContext = useASContext();
    const userRole = asContext?.tenants?.selectedTenant?.UserRoleId;

    // Define settings
    const ASSettings = [
        {
            name: 'Groups and Sub Groups',
            iconImage: '/assets/group-subgroup.png',
            route: "/groups",
        },
         // {
        //     name: 'Categories',
        //     iconImage: '/assets/categories.png',
        //     route: ''
        // },
        // Show Variables only for companyAdmin or ReportAdmin
        ...(userRole?.toLowerCase() === AppEnum.UserRoleIds.CompanyAdmin.toLowerCase() || userRole?.toLowerCase() === AppEnum.UserRoleIds.CollectionAdmin.toLowerCase() ? [
            {
                name: 'Variables',
                iconImage: '/assets/variable.png',
                route: '/variables',
            },
        ] : []),
        // Show Subscription only for CompanyAdmin
        ...(userRole?.toLowerCase() === AppEnum.UserRoleIds.CompanyAdmin.toLowerCase() ? [
            {
                name: 'Subscription Setting',
                iconImage: '/assets/sub.png',
                route: '/update-checkout',
            },
            {
                name: 'User Access Log',
                iconImage: '/assets/logs.svg',
                route: '/user-access-log',
            },
        ] : [])
    ];

    return (
        <div className="setting">
            <div className="d-flex justify-space-between">
                <div>
                    <Typography
                        className="font-weight-bold"
                        sx={{
                            marginBottom: 0,
                            color: styles.secondaryColor,
                            fontFamily: styles.poppins,
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                        gutterBottom
                        variant="body1"
                        component="div"
                    >
                        Settings
                    </Typography>
                </div>
            </div>

            <div className="card-container">
                {ASSettings.map((setting) => (
                    <Card sx={{ width: 280, height: 70 }} key={setting.key} className='setting-card'>
                        <Link
                            className="new-link card-link"
                            to={setting?.route}
                        >
                            <CardActionArea
                                sx={{
                                    display: "flex",
                                    padding: "15px 10px",
                                    height: "100%",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                }}
                            >
                                {setting.iconImage && (
                                    <CardMedia
                                        component="img"
                                        height="28"
                                        sx={{ width: "auto !important" }}
                                        image={setting.iconImage}
                                        alt={setting.iconImage}
                                    />
                                )}

                                <CardContent sx={{ padding: "10px" }}>
                                    <Typography
                                        sx={{
                                            marginBottom: 0,
                                            color: styles.secondaryColor,
                                            fontWeight: 600,
                                        }}
                                        gutterBottom
                                        variant="body1"
                                        component="div"
                                        className="setting-name"
                                    >
                                        {setting.name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                ))}
            </div>
        </div>
    );
};
